import React from "react"
import Layout from "../components/Layout"
import PrivacyComponent from "../components/Privacy/Privacy"
import { graphql, useStaticQuery } from "gatsby"
import HelmetComponent from "../components/Helmet"
import useIsClient from "../hooks/rehydration"
// import Loader from "../components/Loader/Loader"
function Privacy() {
  const { isClient } = useIsClient()

  const data = useStaticQuery(graphql`
    {
      allContentfulSeoEach(
        sort: { fields: createdAt, order: ASC }
        filter: { node_locale: { eq: "hu" } }
      ) {
        edges {
          node {
            id
            title
            url
            description
            facebookImage {
              fixed {
                srcWebp
              }
            }
          }
        }
      }
      allContentfulPrivacyTerms(
        skip: 1
        filter: { node_locale: { eq: "hu" } }
      ) {
        edges {
          node {
            mainTitle
            mainPara {
              raw
            }
            title1
            title2
            title3
            title4
            title5
            para5 {
              raw
            }
            para4 {
              raw
            }
            para3 {
              raw
            }
            para2 {
              raw
            }
            para1 {
              raw
            }
          }
        }
      }
    }
  `)
  let seo = data.allContentfulSeoEach.edges[4].node
  if (!isClient) return <HelmetComponent seo={seo} />
  return (
    <Layout>
      <HelmetComponent seo={seo} />
      <PrivacyComponent data={data} />
    </Layout>
  )
}

export default Privacy

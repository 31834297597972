// import Layout from "../Layout"
import React, { useContext } from "react"
import { ThemeContext } from "../../contexts/ThemeContext"

import { H1, H2, buttonBckg } from "../../globalStyles"
import styled from "styled-components"
// import { graphql, useStaticQuery } from "gatsby"
// import styles from "../styles/HomeReachOutStyle"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
function Privacy({ data }) {
  const { isDarkMode } = useContext(ThemeContext)

  // const data = useStaticQuery(graphql`
  //   {
  //     allContentfulPrivacyTerms(skip: 1) {
  //       edges {
  //         node {
  //           mainTitle
  //           mainPara {
  //             raw
  //           }
  //           title1
  //           title2
  //           title3
  //           title4
  //           title5
  //           para5 {
  //             raw
  //           }
  //           para4 {
  //             raw
  //           }
  //           para3 {
  //             raw
  //           }
  //           para2 {
  //             raw
  //           }
  //           para1 {
  //             raw
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  const { mainTitle, mainPara } = data.allContentfulPrivacyTerms.edges[0].node

  const {
    title1,
    title2,
    title3,
    title4,
    title5,
    para1,
    para2,
    para3,
    para4,
    para5,
  } = data.allContentfulPrivacyTerms.edges[0].node

  let titles = [title1, title2, title3, title4, title5]

  let para = [para1, para2, para3, para4, para5]

  let textGroups = titles.map((x, i) => (
    <>
      <H2
        style={{ color: isDarkMode ? buttonBckg.darkHover : "white" }}
        className="mt-5 mb-4"
        key={i + 100}
      >
        {x}
      </H2>
      <div style={{ fontWeight: 300 }} key={i}>
        {documentToReactComponents(JSON.parse(para[i].raw))}
      </div>
    </>
  ))

  return (
    <FullContainer dark={isDarkMode} className=" text-center   ">
      <div className="row ustify-content-center text-center w-100 ">
        <Col
          dark={isDarkMode}
          titleBool
          className="col-11 text-left justify-content-center  col-md-10 col-lg-9 "
        >
          <H1
            style={{ color: isDarkMode ? buttonBckg.darkHover : "white" }}
            className="text-left mb-4"
          >
            {mainTitle}
          </H1>
          <div style={{ fontWeight: 300 }}>
            {documentToReactComponents(JSON.parse(mainPara.raw))}
          </div>
          {textGroups}
        </Col>
      </div>
    </FullContainer>
  )
}

export default Privacy

export const FullContainer = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  transition: all 400ms;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  background: white;
  overflow: hidden;

  background: rgba(240, 240, 250);

  padding-bottom: 15px;
  ${({ dark }) =>
    dark
      ? `
      

  
  background:rgba(240,240,250);
  
  `
      : ` 


background:rgba(34,33,100,1) ;
 

  `}
`
export const Col = styled.div`
  /* background: Red; */
  /* margin: auto; */
  /* border: 1px solid green; */
  ${({ titleBool }) =>
    titleBool &&
    `margin-top:4%; margin-bottom:2%; margin-left:auto; margin-right: auto;`}

  * {
    line-height: 1.8;
    color: ${({ dark }) =>
      dark
        ? `#505050
    `
        : `white`};
  }
`
